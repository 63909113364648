var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "context" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c(
            "el-input",
            {
              attrs: { placeholder: "请输入素材名称或关键字" },
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.search($event)
                }
              },
              model: {
                value: _vm.searchValue,
                callback: function($$v) {
                  _vm.searchValue = $$v
                },
                expression: "searchValue"
              }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "search",
                  attrs: {
                    slot: "prepend",
                    "popper-append-to-body": false,
                    placeholder: "视频"
                  },
                  on: { change: _vm.selectCategory },
                  slot: "prepend",
                  model: {
                    value: _vm.materialType,
                    callback: function($$v) {
                      _vm.materialType = $$v
                    },
                    expression: "materialType"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "视频", value: "video" } }),
                  _c("el-option", { attrs: { label: "图片", value: "img" } }),
                  _c("el-option", { attrs: { label: "音频", value: "audio" } })
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "searchButton" }, [
            _c("i", {
              staticClass: "iconfont icon-sousuo",
              staticStyle: {
                width: "25px",
                height: "32px",
                cursor: "pointer",
                "padding-top": "15px"
              },
              on: { click: _vm.search }
            })
          ]),
          _c("div", { staticStyle: { width: "26px" } }),
          _c(
            "div",
            {
              staticClass: "screen",
              style: { background: _vm.color },
              on: { click: _vm.showScreen }
            },
            [
              _c("img", {
                staticStyle: { width: "16px", height: "17px" },
                attrs: { src: require("@/assets/images/screen.png"), alt: "" }
              })
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isshow,
              expression: "isshow"
            }
          ],
          staticClass: "screen_container"
        },
        [
          _c("div", { staticStyle: { width: "64px" } }),
          _c(
            "div",
            { staticClass: "screen_context" },
            [
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("span", { staticClass: "demonstration" }, [
                    _vm._v("上传时间")
                  ]),
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "-",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "picker-options": _vm.pickerOptions,
                      "prefix-icon": "el-icon-date",
                      "value-format": "yyyy-MM-dd",
                      "clear-icon": "el-icon-close ",
                      clearable: true,
                      "default-value": _vm.timeDefaultShow
                    },
                    model: {
                      value: _vm.date,
                      callback: function($$v) {
                        _vm.date = $$v
                      },
                      expression: "date"
                    }
                  })
                ],
                1
              ),
              _c("el-divider"),
              _c(
                "div",
                { staticClass: "label", staticStyle: { display: "flex" } },
                [
                  _c("span", { staticClass: "Label" }, [_vm._v("标签")]),
                  _c(
                    "el-select",
                    {
                      class: [
                        "Label_select",
                        _vm.labelList.length ? "" : "disable_select"
                      ],
                      attrs: {
                        slot: "prepend",
                        "popper-append-to-body": false,
                        placeholder: "任意符合",
                        disabled: _vm.labelList.length ? false : true
                      },
                      slot: "prepend",
                      model: {
                        value: _vm.label,
                        callback: function($$v) {
                          _vm.label = $$v
                        },
                        expression: "label"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "任意符合", value: "1" }
                      }),
                      _c("el-option", {
                        attrs: { label: "同时符合", value: "2" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "checkgroup" },
                [
                  _vm.labelList.length
                    ? _c(
                        "el-checkbox",
                        {
                          staticClass: "checkedAll",
                          style: { opacity: _vm.label == "1" ? 1 : 0 },
                          on: { change: _vm.handleCheckAllChange },
                          model: {
                            value: _vm.checkAll,
                            callback: function($$v) {
                              _vm.checkAll = $$v
                            },
                            expression: "checkAll"
                          }
                        },
                        [_vm._v(" 全选")]
                      )
                    : _vm._e(),
                  _c(
                    "el-checkbox-group",
                    {
                      attrs: { max: 20 },
                      on: { change: _vm.handleCheckedCitiesChange },
                      model: {
                        value: _vm.checkedCities,
                        callback: function($$v) {
                          _vm.checkedCities = $$v
                        },
                        expression: "checkedCities"
                      }
                    },
                    _vm._l(_vm.labelList, function(item) {
                      return _c("el-checkbox", {
                        key: item.id,
                        attrs: {
                          checked:
                            _vm.$route.params.tag_id == item.id ? true : false,
                          label: item.tag_name
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c("el-divider"),
              _c(
                "div",
                { staticClass: "other" },
                [
                  _c(
                    "span",
                    { staticStyle: { height: "30px", "line-height": "30px" } },
                    [_vm._v("其他")]
                  ),
                  _vm.materialType != "audio"
                    ? _c(
                        "el-select",
                        {
                          staticClass: "resolving",
                          attrs: {
                            slot: "prepend",
                            "collapse-tags": "",
                            multiple: "",
                            "popper-append-to-body": false,
                            placeholder: "分辨率"
                          },
                          slot: "prepend",
                          model: {
                            value: _vm.resolving,
                            callback: function($$v) {
                              _vm.resolving = $$v
                            },
                            expression: "resolving"
                          }
                        },
                        _vm._l(_vm.resolveData, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item, value: item }
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.materialType != "audio"
                    ? _c(
                        "el-select",
                        {
                          staticClass: "scale",
                          attrs: {
                            slot: "prepend",
                            "collapse-tags": "",
                            multiple: "",
                            "popper-append-to-body": false,
                            placeholder: "比例"
                          },
                          slot: "prepend",
                          model: {
                            value: _vm.scale,
                            callback: function($$v) {
                              _vm.scale = $$v
                            },
                            expression: "scale"
                          }
                        },
                        _vm._l(_vm.scaleData, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: _vm._f("formatScale")(item),
                              value: item
                            }
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-select",
                    {
                      class: [
                        "format",
                        _vm.materialType == "audio" ? "format_ml" : ""
                      ],
                      attrs: {
                        slot: "prepend",
                        "collapse-tags": "",
                        multiple: "",
                        "popper-append-to-body": false,
                        placeholder: "格式"
                      },
                      slot: "prepend",
                      model: {
                        value: _vm.format,
                        callback: function($$v) {
                          _vm.format = $$v
                        },
                        expression: "format"
                      }
                    },
                    _vm._l(_vm.formatData, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item, value: item }
                      })
                    }),
                    1
                  ),
                  _c("el-cascader", {
                    key: _vm.cascaderKey,
                    ref: "cascader",
                    staticClass: "upload",
                    attrs: {
                      props: { multiple: true },
                      "collapse-tags": true,
                      "show-all-levels": false,
                      "popper-class": "upload-user",
                      "popper-append-to-body": false,
                      placeholder: "上传者",
                      options: _vm.options
                    },
                    model: {
                      value: _vm.upUser,
                      callback: function($$v) {
                        _vm.upUser = $$v
                      },
                      expression: "upUser"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "Btn" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "20px" },
                      on: { click: _vm.reset }
                    },
                    [_vm._v("重置")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "42px" },
                      on: { click: _vm.filter }
                    },
                    [_vm._v("筛选")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticStyle: { width: "80px" } })
        ]
      ),
      !_vm.matchMaterial
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.ifLoading,
                  expression: "ifLoading"
                }
              ],
              ref: "middleListContent",
              staticClass: "container",
              style: _vm.isshow ? "height:47vh" : "height:83vh",
              attrs: {
                "element-loading-background": "rgba(0, 0, 0, 0.1)",
                "element-loading-text": "素材加载中..."
              }
            },
            [
              _vm.materialType == "video"
                ? _c(
                    "div",
                    {
                      staticClass: "videoContext",
                      staticStyle: {
                        "min-height": "100%",
                        display: "flex",
                        "flex-direction": "column",
                        "justify-content": "space-between"
                      }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "totalORorder" }, [
                            _c("div", { staticClass: "total" }, [
                              _vm._v(
                                "共" + _vm._s(_vm.materialTotal) + "个素材"
                              )
                            ]),
                            _c("div", { staticClass: "order" }, [
                              _c("div", [_vm._v("排序：")]),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showDefault,
                                      expression: "showDefault"
                                    }
                                  ],
                                  staticClass: "default"
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      style:
                                        "background:" +
                                        (_vm.num == 0 ? "#2D2D3D" : "#212130 "),
                                      attrs: {
                                        disabled:
                                          _vm.materialTotal == 0 ? true : false
                                      },
                                      on: { click: _vm.Default }
                                    },
                                    [
                                      _vm._v("默认 "),
                                      _vm.defaultArrow == "bottom"
                                        ? _c("i", {
                                            staticClass: "el-icon-bottom"
                                          })
                                        : _vm._e(),
                                      _vm.defaultArrow == "top"
                                        ? _c("i", {
                                            staticClass: "el-icon-top"
                                          })
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "uptime" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      style:
                                        "background:" +
                                        (_vm.num == 1
                                          ? "#2D2D3D"
                                          : "#212130 ") +
                                        ";border-radius:" +
                                        (_vm.showDefault == true
                                          ? "0px 0px 0px 0px"
                                          : "6px 0px 0px 6px") +
                                        ";",
                                      attrs: {
                                        disabled:
                                          _vm.materialTotal == 0 ? true : false
                                      },
                                      on: { click: _vm.Uptime }
                                    },
                                    [
                                      _vm._v("上传时间 "),
                                      _vm.uploadArrow == "bottom"
                                        ? _c("i", {
                                            staticClass: "el-icon-bottom"
                                          })
                                        : _vm._e(),
                                      _vm.uploadArrow == "top"
                                        ? _c("i", {
                                            staticClass: "el-icon-top"
                                          })
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "time" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      style:
                                        "background:" +
                                        (_vm.num == 2 ? "#2D2D3D" : "#212130 "),
                                      attrs: {
                                        disabled:
                                          _vm.materialTotal == 0 ? true : false
                                      },
                                      on: { click: _vm.Time }
                                    },
                                    [
                                      _vm._v("时长"),
                                      _vm.timeArrow == "bottom"
                                        ? _c("i", {
                                            staticClass: "el-icon-bottom"
                                          })
                                        : _vm._e(),
                                      _vm.timeArrow == "top"
                                        ? _c("i", {
                                            staticClass: "el-icon-top"
                                          })
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ]),
                          _vm.materialData.length
                            ? _c(
                                "div",
                                { staticClass: "material_content" },
                                _vm._l(_vm.materialData, function(item, index) {
                                  return _c(
                                    "div",
                                    { key: item.ip_id, staticClass: "video" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "videoSize",
                                          attrs: { id: item.ip_id },
                                          on: {
                                            mouseleave: _vm.imgLeave,
                                            mouseenter: _vm.imgEnter,
                                            click: function($event) {
                                              return _vm.goMaterialDetail(
                                                item.ip_id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            class: [
                                              "gifNode",
                                              item.gifNode ? "gifNode_show" : ""
                                            ],
                                            staticStyle: { cursor: "pointor" },
                                            attrs: {
                                              src: item.gifNode,
                                              alt: ""
                                            }
                                          }),
                                          item.showImg
                                            ? _c("img", {
                                                staticClass: "imgNode",
                                                staticStyle: {
                                                  cursor: "pointor"
                                                },
                                                attrs: {
                                                  src: item.cover_img,
                                                  alt: ""
                                                }
                                              })
                                            : _vm._e(),
                                          !item.cover_img
                                            ? _c("i", {
                                                staticClass:
                                                  "iconfont icon-shipin1"
                                              })
                                            : _vm._e()
                                        ]
                                      ),
                                      _c("div", { staticClass: "btns" }, [
                                        _c("img", {
                                          staticClass: "ico_sty",
                                          attrs: {
                                            src: require("@/assets/images/down.png"),
                                            alt: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.download(
                                                item.url,
                                                item.ip_id
                                              )
                                            }
                                          }
                                        }),
                                        _c("img", {
                                          staticClass: "ico_sty",
                                          attrs: {
                                            src: require("@/assets/images/add.png"),
                                            alt: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addList(item.ip_id)
                                            }
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.addCollet(item.ip_id)
                                              }
                                            }
                                          },
                                          [
                                            item.collect_if == 1
                                              ? _c("img", {
                                                  staticClass: "ico_sty",
                                                  attrs: {
                                                    src: require("@/assets/images/colleted.png")
                                                  }
                                                })
                                              : _c("img", {
                                                  staticClass: "ico_sty",
                                                  attrs: {
                                                    src: require("@/assets/images/collet.png")
                                                  }
                                                })
                                          ]
                                        )
                                      ]),
                                      _c(
                                        "span",
                                        { staticClass: "video_duration" },
                                        [_vm._v(_vm._s(item.duration))]
                                      ),
                                      _c(
                                        "el-tooltip",
                                        {
                                          directives: [
                                            {
                                              name: "delTabIndex",
                                              rawName: "v-delTabIndex"
                                            }
                                          ],
                                          attrs: {
                                            placement:
                                              !_vm.filterNum(index + 1) &&
                                              item.name.length > 24
                                                ? "bottom-end"
                                                : "bottom-start",
                                            "popper-class":
                                              "my_material_tooltip",
                                            content: item.name
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "videoTitle one_line_ellipsis",
                                              on: {
                                                click: function($event) {
                                                  return _vm.goMaterialDetail(
                                                    item.ip_id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.name) + " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          !_vm.materialData.length && !_vm.ifLoading
                            ? _c("noResult", {
                                style: _vm.isshow
                                  ? "margin-top:5%"
                                  : "margin-top:15%",
                                attrs: {
                                  iconUrl: "icon-meiyoujieguo",
                                  iconSty: "font-size:80px",
                                  textSty: "font-size:18px",
                                  noResultText: "没有符合条件的素材"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm.materialType == "img"
                ? _c(
                    "div",
                    {
                      staticClass: "videoContext",
                      staticStyle: {
                        "min-height": "100%",
                        display: "flex",
                        "flex-direction": "column",
                        "justify-content": "space-between"
                      }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "totalORorder" }, [
                            _c("div", { staticClass: "total" }, [
                              _vm._v(
                                "共" + _vm._s(_vm.materialTotal) + "个素材"
                              )
                            ]),
                            _c("div", { staticClass: "order" }, [
                              _c("div", [_vm._v("排序：")]),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showDefault,
                                      expression: "showDefault"
                                    }
                                  ],
                                  staticClass: "default"
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      style:
                                        "background:" +
                                        (_vm.num == 0 ? "#2D2D3D" : "#212130 "),
                                      on: { click: _vm.Default }
                                    },
                                    [
                                      _vm._v("默认"),
                                      _vm.defaultArrow == "bottom"
                                        ? _c("i", {
                                            staticClass: "el-icon-bottom"
                                          })
                                        : _vm._e(),
                                      _vm.defaultArrow == "top"
                                        ? _c("i", {
                                            staticClass: "el-icon-top"
                                          })
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "uptime" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      style:
                                        "background:" +
                                        (_vm.num == 1
                                          ? "#2D2D3D"
                                          : "#212130 ") +
                                        ";border-radius:" +
                                        (_vm.showDefault == true
                                          ? "0px 6px 6px 0px"
                                          : "6px") +
                                        ";",
                                      on: { click: _vm.Uptime }
                                    },
                                    [
                                      _vm._v("上传时间"),
                                      _vm.uploadArrow == "bottom"
                                        ? _c("i", {
                                            staticClass: "el-icon-bottom"
                                          })
                                        : _vm._e(),
                                      _vm.uploadArrow == "top"
                                        ? _c("i", {
                                            staticClass: "el-icon-top"
                                          })
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ]),
                          _vm.materialData.length
                            ? _c(
                                "div",
                                { staticClass: "material_content" },
                                _vm._l(_vm.materialData, function(item, index) {
                                  return _c(
                                    "div",
                                    { key: item.ip_id, staticClass: "video" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "videoSize img_size",
                                          on: {
                                            click: function($event) {
                                              return _vm.goMaterialDetail(
                                                item.ip_id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: { src: item.cover, alt: "" }
                                          })
                                        ]
                                      ),
                                      _c("div", { staticClass: "btns" }, [
                                        _c("img", {
                                          staticClass: "ico_sty",
                                          attrs: {
                                            src: require("@/assets/images/down.png"),
                                            alt: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.download(
                                                item.url,
                                                item.name
                                              )
                                            }
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.addCollet(item.ip_id)
                                              }
                                            }
                                          },
                                          [
                                            item.collect_if == 1
                                              ? _c("img", {
                                                  staticClass: "ico_sty",
                                                  attrs: {
                                                    src: require("@/assets/images/colleted.png")
                                                  }
                                                })
                                              : _c("img", {
                                                  staticClass: "ico_sty",
                                                  attrs: {
                                                    src: require("@/assets/images/collet.png")
                                                  }
                                                })
                                          ]
                                        )
                                      ]),
                                      _c(
                                        "el-tooltip",
                                        {
                                          directives: [
                                            {
                                              name: "delTabIndex",
                                              rawName: "v-delTabIndex"
                                            }
                                          ],
                                          attrs: {
                                            placement:
                                              !_vm.filterNum(index + 1) &&
                                              item.name.length > 24
                                                ? "bottom-end"
                                                : "bottom-start",
                                            "popper-class":
                                              "my_material_tooltip",
                                            content: item.name
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "videoTitle one_line_ellipsis",
                                              on: {
                                                click: function($event) {
                                                  return _vm.goMaterialDetail(
                                                    item.ip_id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.name) + " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          !_vm.materialData.length && !_vm.ifLoading
                            ? _c("noResult", {
                                style: _vm.isshow
                                  ? "margin-top:5%"
                                  : "margin-top:15%",
                                attrs: {
                                  iconUrl: "icon-meiyoujieguo",
                                  iconSty: "font-size:80px",
                                  textSty: "font-size:18px",
                                  noResultText: "没有符合条件的素材"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm.materialType == "audio"
                ? _c(
                    "div",
                    {
                      staticClass: "videoContext",
                      staticStyle: {
                        "min-height": "100%",
                        display: "flex",
                        "flex-direction": "column",
                        "justify-content": "space-between"
                      }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "totalORorder" }, [
                            _c("div", { staticClass: "total" }, [
                              _vm._v(
                                "共" + _vm._s(_vm.materialTotal) + "个素材"
                              )
                            ]),
                            _c("div", { staticClass: "order" }, [
                              _c("div", [_vm._v("排序：")]),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showDefault,
                                      expression: "showDefault"
                                    }
                                  ],
                                  staticClass: "default"
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      style:
                                        "background:" +
                                        (_vm.num == 0 ? "#2D2D3D" : "#212130 "),
                                      attrs: {
                                        disabled:
                                          _vm.materialTotal == 0 ? true : false
                                      },
                                      on: { click: _vm.Default }
                                    },
                                    [
                                      _vm._v("默认 "),
                                      _vm.defaultArrow == "bottom"
                                        ? _c("i", {
                                            staticClass: "el-icon-bottom"
                                          })
                                        : _vm._e(),
                                      _vm.defaultArrow == "top"
                                        ? _c("i", {
                                            staticClass: "el-icon-top"
                                          })
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "uptime" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      style:
                                        "background:" +
                                        (_vm.num == 1
                                          ? "#2D2D3D"
                                          : "#212130 ") +
                                        ";border-radius:" +
                                        (_vm.showDefault == true
                                          ? "0px 0px 0px 0px"
                                          : "6px 0px 0px 6px") +
                                        ";",
                                      attrs: {
                                        disabled:
                                          _vm.materialTotal == 0 ? true : false
                                      },
                                      on: { click: _vm.Uptime }
                                    },
                                    [
                                      _vm._v("上传时间 "),
                                      _vm.uploadArrow == "bottom"
                                        ? _c("i", {
                                            staticClass: "el-icon-bottom"
                                          })
                                        : _vm._e(),
                                      _vm.uploadArrow == "top"
                                        ? _c("i", {
                                            staticClass: "el-icon-top"
                                          })
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "time" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      style:
                                        "background:" +
                                        (_vm.num == 2 ? "#2D2D3D" : "#212130 "),
                                      attrs: {
                                        disabled:
                                          _vm.materialTotal == 0 ? true : false
                                      },
                                      on: { click: _vm.Time }
                                    },
                                    [
                                      _vm._v("时长"),
                                      _vm.timeArrow == "bottom"
                                        ? _c("i", {
                                            staticClass: "el-icon-bottom"
                                          })
                                        : _vm._e(),
                                      _vm.timeArrow == "top"
                                        ? _c("i", {
                                            staticClass: "el-icon-top"
                                          })
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ]),
                          _vm.materialData.length
                            ? _c(
                                "div",
                                { staticClass: "material_content" },
                                _vm._l(_vm.materialData, function(item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.ip_id,
                                      staticClass: "audio_item"
                                    },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          directives: [
                                            {
                                              name: "delTabIndex",
                                              rawName: "v-delTabIndex"
                                            }
                                          ],
                                          attrs: {
                                            placement:
                                              !_vm.filterNum(index + 1) &&
                                              item.name.length > 24
                                                ? "bottom-end"
                                                : "bottom-start",
                                            "popper-class":
                                              "my_material_tooltip",
                                            content: item.name
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "audio_title one_line_ellipsis",
                                              on: {
                                                click: function($event) {
                                                  return _vm.goMaterialDetail(
                                                    item.ip_id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.name) + " "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c("div", { staticClass: "center" }, [
                                        _c(
                                          "div",
                                          { staticClass: "audio_author" },
                                          [_vm._v(_vm._s(item.photographer))]
                                        ),
                                        _c("div", {
                                          ref: "waveform",
                                          refInFor: true,
                                          staticClass: "waveform",
                                          attrs: { id: "waveform" + item.ip_id }
                                        }),
                                        item.isplay
                                          ? _c("span", {
                                              staticClass:
                                                "control_icon control_play",
                                              on: {
                                                click: function($event) {
                                                  return _vm.audioPause(item)
                                                }
                                              }
                                            })
                                          : _vm._e(),
                                        !item.isplay
                                          ? _c("span", {
                                              staticClass:
                                                "control_icon control_pause",
                                              on: {
                                                click: function($event) {
                                                  return _vm.audioPlay(item)
                                                }
                                              }
                                            })
                                          : _vm._e(),
                                        _c("div", { staticClass: "btns" }, [
                                          _c("img", {
                                            staticClass: "ico_sty",
                                            staticStyle: {
                                              "margin-right": "5px"
                                            },
                                            attrs: {
                                              src: require("@/assets/images/down.png"),
                                              alt: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.download(
                                                  item.url,
                                                  item.name
                                                )
                                              }
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "inline-block"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addCollet(
                                                    item.ip_id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              item.collect_if == 1
                                                ? _c("img", {
                                                    staticClass: "ico_sty",
                                                    attrs: {
                                                      src: require("@/assets/images/colleted.png")
                                                    }
                                                  })
                                                : _c("img", {
                                                    staticClass: "ico_sty",
                                                    attrs: {
                                                      src: require("@/assets/images/collet.png")
                                                    }
                                                  })
                                            ]
                                          )
                                        ])
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "audio_duration" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.duration) + " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          !_vm.materialData.length && !_vm.ifLoading
                            ? _c("noResult", {
                                style: _vm.isshow
                                  ? "margin-top:5%"
                                  : "margin-top:15%",
                                attrs: {
                                  iconUrl: "icon-meiyoujieguo",
                                  iconSty: "font-size:80px",
                                  textSty: "font-size:18px",
                                  noResultText: "没有符合条件的素材"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _c("el-pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.materialData.length && _vm.ifLoading == false,
                    expression: "materialData.length && ifLoading == false"
                  }
                ],
                attrs: {
                  background: true,
                  "current-page": _vm.page,
                  layout: "prev, pager, next, jumper",
                  "page-size": 40,
                  total: _vm.materialTotal,
                  "hide-on-single-page": true
                },
                on: { "current-change": _vm.handleCurrentChange }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.none && _vm.matchMaterial
        ? _c("noResult", {
            style: _vm.isshow ? "margin-top:10%;height:auto;" : "",
            attrs: {
              iconUrl: "icon-meiyousucai",
              iconSty: "font-size:80px",
              textSty: "font-size:18px",
              noResultText: "暂时没有素材"
            }
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "下载",
            visible: _vm.downloadDialog,
            width: "461px",
            top: "30vh",
            "custom-class": "material-dialog",
            "show-close": true
          },
          on: {
            "update:visible": function($event) {
              _vm.downloadDialog = $event
            }
          }
        },
        [
          _c(
            "span",
            { staticStyle: { display: "block", "margin-bottom": "21px" } },
            [_vm._v("分辨率：")]
          ),
          _c(
            "div",
            { staticClass: "radio_check" },
            [
              _vm.resolutions.length == 1
                ? _c(
                    "el-radio",
                    { staticClass: "disable_radio", attrs: { label: "标清" } },
                    [_vm._v("标清")]
                  )
                : _vm._e(),
              _vm._l(_vm.resolutions, function(item, index) {
                return _c(
                  "el-radio",
                  {
                    key: index,
                    attrs: { label: item.url },
                    model: {
                      value: _vm.system,
                      callback: function($$v) {
                        _vm.system = $$v
                      },
                      expression: "system"
                    }
                  },
                  [_vm._v(_vm._s(item.resolution))]
                )
              })
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.downloadConfirm }
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "30px" },
                  on: {
                    click: function($event) {
                      _vm.downloadDialog = false
                    }
                  }
                },
                [_vm._v("取 消")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }